<template>
  <div class="login-section section">
    <div class="login-section-form">
      <div class="login-section-form-container">
        <div class="login-section-form-container-art">
          <art dir="top" />
        </div>
        <div class="login-section-form-container-inner">
          <div class="login-section-picture">
            <h1 class="login-section-form-title third-font" dcolor>Models 1A</h1>
          </div>
          <div>
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import art from "../../components/art/art.vue";
export default {
  components: { art },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.login-section {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white_smoke;
  &-form {
    width: 360px;
    padding: $mpadding;
    &-container {
      position: relative;
      padding: $mpadding;
      background: $white;
      border-radius: $mradius/2;
      box-shadow: $dshadow;
      height: 90vh;
      max-height: 650px;
      display: flex;
      align-items: center;
      overflow: hidden;
      &-inner {
        position: relative;
        margin: auto;
        z-index: 2;
      }
      &-art {
        position: absolute;
        width: 100%;
        top: -45px;
        left: 0;
        .line {
          background: $primary_color_saturate;
          border-radius: 4pc;
          transform: rotate(145deg);
          position: relative;
          &:nth-child(1) {
            width: 100%;
            height: 80px;
          }
          &:nth-child(2) {
            width: 100%;
            height: 50px;
            left: 120px;
            top: -72px;
          }
          &:nth-child(3) {
            width: 100%;
            height: 50px;
            left: 225px;
            top: -105px;
          }
          &:nth-child(4) {
            width: 50px;
            height: 50px;
            left: 25px;
            top: 0px;
          }
        }
      }
    }
    &-title {
      font-size: 32px;
    }
    &-data {
      .block-input {
        margin-top: 10px;
        position: relative;
        input {
          width: 100%;
          border-radius: 6px;
          appearance: none;
          border: none;
          height: 42px;
          text-align: left;
          font-size: 100%;
          padding-left: 7px;
          &[type="nosubmit"] {
            background: $primary_color;
            color: $white;
            cursor: pointer;
            text-align: center;
            padding-left: 0;
            appearance: none;
            border-radius: 2pc;
            margin: $mpadding * 2 0;
            height: 38px;
            &:active {
              opacity: 0.75;
            }
          }
          &.data-error {
            border: solid 1px $primary_color;
          }
        }
        &:nth-child(-n + 2) {
          border-bottom: solid 1px $alto;
        }
        input ~ label {
          position: absolute;
          pointer-events: none;
          transition: 0.3s ease-in-out;
          border-radius: 3px;
          top: 25%;
          left: 0;
          width: 100%;
        }
        input[type="text"]:not(empty),
        input[type="email"]:not(empty),
        input[type="password"]:not(empty) {
          padding-top: 15px;
        }
        input:not(empty) ~ label {
          top: 2px;
          left: 7px;
          font-size: 75%;
          width: auto;
          opacity: 0.8;
        }
      }
      .block-footer {
        margin-top: $mpadding;
      }
    }
  }
  &-picture {
    margin: $mpadding * 2 0;
  }
  @media (min-width: $tablet_width) {
    &-form {
      &-container {
        &-art {
          top: 0;
        }
      }
    }
  }
}
</style>
